import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout'
import Img from "gatsby-image"
import SEO from '../components/seo'

import AOS from 'aos';
import 'aos/dist/aos.css';

import '../styles/projectview.scss'
import Arrow from '../svgs/Arrow.inline.svg'




export default function singleProjectView({data}) {


  var hasLink = false;
  if (data.contentfulProjekt.externerLink && data.contentfulProjekt.externerLink != "null") hasLink = true;

    return (
        <Layout>
          <SEO title={data.contentfulProjekt.projekttitel} />
          <div className="wrapper">
            <div className="main-title-section flex flex-col justify-between mb-8 md:justify-center">
              <div id="flexhelper illustration" />
              <div className="title-wrapper">
                <h3 className="text-soYellow" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">Projekt</h3>
                <span className="flex flex-row items-center" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="1000">
                 <h1 className="text-3xl md:text-5xl underline-custom" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">{data.contentfulProjekt.projekttitel}</h1>
                 {hasLink && // conditional rendering based on link availability
                    <a data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" className="arrowcontainer flex justify-center rounded-full border-2 h-12 w-12 border-soYellow ml-8 transition duration-300 ease-in-out" href={data.contentfulProjekt.externerLink} target="_blank">
                    <Arrow className="arrow-item h-4 self-center transition duration-300"/>
                    </a>
                 }
                </span>
                <span className="link-wrapper md:ml-8 lg:ml-12">
                  
                </span>
                
              </div>
              <div className="details-wrapper flex flex-row justify-between mr-4 md:mt-8 md:justify-start md:items-baseline">
                <div className="customer-wrapper md:mr-8 lg:mr-12">
                  <p className="font-semibold" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="800">Kunde</p>
                  <p className="font-regular" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="1000">{data.contentfulProjekt.kunde}</p>
                </div>
                <div className="category-wrapper md:mx-8 lg:mx-12">
                  <p className="font-semibold" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="800">Kategorie</p>
                  <p className="font-regular" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="1000">{data.contentfulProjekt.kategorie.map((kategorie, index) => ((index ? ', ' : '') + kategorie.kategorie))}</p>
                </div>
                <div className="year-wrapper md:ml-8 lg:ml-12">
                  <p className="font-semibold" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="800">Jahr</p>
                  <p className="font-regular" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="1000">{data.contentfulProjekt.jahr}</p>
                </div>

              </div>
            </div>

            <div id="main-pic-container" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800" className="bg-cover bg-center" style={{ backgroundImage: `url("http://${data.contentfulProjekt.titelbild.file.url.slice(2)}` }}>
            </div>

            <div id="approach-wrapper" className="flex flex-col justify-center py-20 md:py-40 md:flex-row md:justify-between">
              <span className="underline inline-block mb-6 md:w-1/3">
                <h2 className="text-3xl md:text-4xl">{data.contentfulProjekt.einleitungstitel}</h2>
              </span>
              <p className="leading-loose md:w-2/3">
                {data.contentfulProjekt.einleitungstext.einleitungstext}
              </p>
            </div>

            <div className="pictures-wrapper md:flex md:flex-row md:justify-between md:flex-wrap mb-16">
              {data.contentfulProjekt.bilder.map((bilder, index) => (
                <div key={index} className="content-pic-container py-4 ">
                  <Img fluid={bilder.fluid}></Img>
                </div>
              ))}
            </div>

          </div>
            
        </Layout>
    )
}

export const query = graphql`
  query($id: String!) {
    contentfulProjekt(id: {eq: $id}) {
      projekttitel
      externerLink
      kunde
      kategorie {
        kategorie
      }
      jahr
      einleitungstitel
      einleitungstext {
        einleitungstext
      }
      bilder {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_noBase64
        }
        file {
          url
        }
      }
      titelbild {
        file {
          url
        }
      }
    }
  }
`



  